<template>
  <div class="news-page">
    <div class="bannar">
      <!-- <img v-if="bannerSrc" :src="bannerSrc">
            <img v-else src="../../image/bg.png"> -->
      <img :src="bannerSrc" v-if="bannerType" />
      <video
        v-else
        muted="muted"
        id="startvideo"
        class="video"
        :src="bannerSrc"
        type="video/mp4"
        autoplay="autoplay"
        controls="controls"
        loop="-1"
      >
        <p>你的浏览器不支持video标签.</p>
      </video>
      <div class="overlay"></div>
      <div class="bannar-info">
        <div class="bannar-title">赛道探索</div>
        <div class="bannar-detail">{{ bannerTitle }}</div>
      </div>
    </div>
    <main>
      <div style="textalign: center">
        <div class="title">{{ info.name }}</div>
        <div class="news-details-time">发布时间：{{ info.createTime }}</div>
        <div class="detials-border"></div>
        <img :src="info.src" style="width: 100%" />
      </div>
      <div
        v-html="info.details"
        style="textalign: center"
        class="details-content"
      ></div>
      <div style="width: 100%; height: 1px; background-color: #dedede"></div>
      <div class="next-activity">
        <div class="left"></div>
        <div class="right" v-if="!_isMobile()">
          <span>分享到：</span>
          <div class="right-wx" @click.stop="shareToWX">
            <img src="../../../image/wx.png" alt="" />
            <div class="right-wxShare" v-show="wxShow">
              <div class="wxShare-close" @click.stop="wxShow = false">
                <i class="el-icon-close"></i>
              </div>
              <vueQr
                :margin="0"
                :size="280"
                :whiteMargin="true"
                :logoMargin="3"
                :logoCornerRadius="20"
                :text="wxHref"
              ></vueQr>
              <p>用微信扫码二维码</p>
              <p>分享至好友和朋友圈</p>
            </div>
          </div>
          <img src="../../../image/wb.png" alt="" @click="shareToMicroblog" />
        </div>
      </div>
    </main>
    <!-- <bottom-box></bottom-box> -->
  </div>
</template>
<script>
import { getBanner, getTrackInfo } from "../../../api/homeApi";
import vueQr from "vue-qr";

export default {
  components: {
    vueQr,
  },
  data() {
    return {
      bannerSrc: "",
      bannerType: 1, //1.图片 0.视频
      bannerTitle: "",
      newsList: [],
      info: {},
      wxHref: window.location.href,
      // wxHref: 'https://saidao.cdls666.com:8803/activity-details/55',
      wxShow: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getBanner();
    this.getInfo();
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    },
    getBanner() {
      getBanner({
        plate: 1,
      }).then((res) => {
        if (res.code == 0) {
          if (res.infos.length > 0) {
            if (this._isMobile()) {
              this.bannerType = 1;
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].mobileImage)[0].raw
              );
            } else {
              let arr = JSON.parse(res.infos[0].image);
              if (
                [
                  "image/png", // .png
                  "image/jpeg", // .jpg .jpeg
                  "image/x-ms-bmp", // .bmp
                  "image/gif", // .gif
                  "image/tiff", // .tif .tiff
                ].includes(arr[0].type)
              ) {
                this.bannerType = 1;
              } else {
                this.bannerType = 0;
              }
              this.bannerSrc = this.formatImageUrl(
                JSON.parse(res.infos[0].image)[0].raw
              );
            }
            this.bannerTitle = res.infos[0].content;
          }
        }
      });
    },
    // 获取咨询
    getInfo() {
      getTrackInfo({
        id: this.id,
      }).then((res) => {
        if (res.code == 0) {
          res.info.src = this.formatImageUrl(JSON.parse(res.info.image)[0].raw);
          this.info = res.info;
          this.info.createTime = this.info.createTime.split(" ")[0];
        }
      });
    },
    // 分享到微博
    shareToMicroblog() {
      window.open(
        "https://service.weibo.com/share/share.php?url=" +
          window.location.href +
          "&title=" +
          "天府国际赛道"
      );
    },
    shareToWX() {
      if (this._isMobile()) {
        //    window.open('http://zixuephp.net/inc/qrcode_img.php?url=' + window.location.href)
      } else {
        this.wxShow = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and(max-width:900px) {
  .news-page {
    .bannar {
      width: 100%;
      // height: 5.5rem;
      margin-top: 20px;
      background: #666;
      position: relative;
      .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
        z-index: 9;
      }
      img {
        vertical-align: middle;
        height: 30vw;
        width: 100%;
      }
      .bannar-info {
        position: absolute;
        top: 20%;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 0 20px;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    main {
      box-sizing: border-box;
      // width: 1200px;
      padding: 0.8rem 20px;
      background-color: #fff;
      margin: 0 auto;
      .title {
        font-size: 32px;
        margin: 20px 0;
      }
    }
    .news-details-time {
      width: 100%;
      text-align: center;
      font-size: 22px;
      color: #999;
      margin: 20px 0;
    }
  }
}
@media screen and(min-width:900px) {
  .news-page {
    .bannar {
      width: 100%;
      // height: 5.5rem;
      background: #666;
      position: relative;
      img {
        vertical-align: middle;
        height: 550px;
        width: 100%;
      }
      .bannar-info {
        position: absolute;
        top: 40%;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 9;
        .bannar-title {
          font-size: 36px;
          color: #fff;
          margin-bottom: 20px;
        }
        .bannar-detail {
          font-size: 24px;
          color: #fff;
        }
      }
    }
    main {
      box-sizing: border-box;
      width: 1200px;
      padding: 60px 0rem;
      background-color: #fff;
      margin: 0 auto;
      .title {
        font-size: 32px;
        color: #333;
        // margin: 20px 0;
      }
    }
    .news-details-time {
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: #999;
      margin: 20px 0;
    }
  }
}
/deep/.details-content {
  p {
    span {
      color: #666 !important;
    }
    img {
      width: 100% !important;
    }
  }
}
.detials-border {
  border: 1px solid #dedede;
  margin-bottom: 40px;
}
</style>